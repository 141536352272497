import { DataNode } from 'antd/lib/tree';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';

const permissionsTree: DataNode[] = [
    {
        key: PermissionsEnum.Accounts,
        title: 'Accounts',
    },
    {
        children: [
            {
                key: PermissionsEnum.AdministratorMagicLink,
                title: 'Magic Link',
            },
        ],
        key: PermissionsEnum.Administrators,
        title: 'Administrators',
    },
    {
        key: PermissionsEnum.Advisers,
        title: 'Advisers',
    },
    {
        children: [
            {
                key: PermissionsEnum.ApplicationConditionDocumentApprove,
                title: 'Approve Condition Documents',
            },
            {
                key: PermissionsEnum.ApplicationConditionalApprove,
                title: 'Approve Conditional Approval',
            },
            {
                key: PermissionsEnum.ApplicationFormalApprovalAdd,
                title: 'Issue Formal Approval',
            },
            {
                key: PermissionsEnum.ApplicationSettle,
                title: 'Settle',
            },
            {
                key: PermissionsEnum.ApplicationWarehouses,
                title: 'Warehouses',
            },
            {
                key: PermissionsEnum.ApplicationsSettlementReport,
                title: 'Settlement Report',
            },
        ],
        key: PermissionsEnum.Applications,
        title: 'Applications',
    },
    {
        children: [
            {
                key: PermissionsEnum.BrokerAssignBdm,
                title: 'Assign BDMs',
            },
        ],
        key: PermissionsEnum.Brokers,
        title: 'Brokers',
    },
    {
        children: [
            {
                key: PermissionsEnum.InvestmentPendingDistributions,
                title: 'Pending Distributions',
            },
            {
                key: PermissionsEnum.IncomeTrustProcessInterest,
                title: 'Process Income Trust Interest',
            },
        ],
        key: PermissionsEnum.Investments,
        title: 'Investments',
    },
    {
        children: [
            {
                key: PermissionsEnum.InvestorAssignAdviser,
                title: 'Assign Advisers',
            },
            {
                key: PermissionsEnum.InvestorAssignIm,
                title: 'Assign IMs',
            },
            {
                key: PermissionsEnum.InvestorBankAccountConfirm,
                title: 'Confirm Bank Accounts',
            },
            {
                key: PermissionsEnum.InvestorIncomeTrustInvestmentRequests,
                title: 'Income Trust Investment Requests',
            },
            {
                key: PermissionsEnum.InvestorPendingAccounts,
                title: 'Pending Accounts',
            },
            {
                key: PermissionsEnum.InvestorPendingDeposits,
                title: 'Pending Deposits',
            },
            {
                key: PermissionsEnum.InvestorPendingWithdrawals,
                title: 'Pending Withdrawals',
            },
            {
                key: PermissionsEnum.InvestorReferrals,
                title: 'Referrals',
            },
            {
                key: PermissionsEnum.WithdrawalAbas,
                title: 'Withdrawal Abas',
            },
        ],
        key: PermissionsEnum.Investors,
        title: 'Investors',
    },
    {
        children: [
            {
                key: PermissionsEnum.DealLimitsOverride,
                title: 'Override Limits',
            },
            {
                key: PermissionsEnum.DealQuoteApproval,
                title: 'Quote Approval',
            },
            {
                key: PermissionsEnum.LeadsReject,
                title: 'Reject',
            },
            {
                key: PermissionsEnum.LeadsOriginationReport,
                title: 'Origination Report',
            },
            {
                key: PermissionsEnum.LeadsMovementReport,
                title: 'Movement Report',
            },
        ],
        key: PermissionsEnum.Leads,
        title: 'Leads',
    },
    {
        children: [
            {
                key: PermissionsEnum.LoanAssign,
                title: 'Assign Loan Manager',
            },
            {
                key: PermissionsEnum.LoanExtend,
                title: 'Extend Loan',
            },
            {
                key: PermissionsEnum.LoanGracePeriodsAdd,
                title: 'Add Grace Period',
            },
            {
                key: PermissionsEnum.LoanPayoutFigureApprove,
                title: 'Approve Draft Loan Payout Figures',
            },
            {
                key: PermissionsEnum.LoanPayoutFigureProcess,
                title: 'Process Loan Payout Figures',
            },
            {
                key: PermissionsEnum.LoanPayoutFigureForceProcess,
                title: 'Force Process Loan Payout Figures',
            },
        ],
        key: PermissionsEnum.Loans,
        title: 'Loans',
    },
    {
        key: PermissionsEnum.ReferralPartners,
        title: 'Referral Partners',
    },
    {
        children: [
            {
                key: PermissionsEnum.UserBlock,
                title: 'Block User',
            },
        ],
        key: PermissionsEnum.Users,
        title: 'Users',
    },
    {
        children: [
            {
                key: PermissionsEnum.ReportsApplicationsTimeInStatus,
                title: 'Applications - Time In Status',
            },
            {
                key: PermissionsEnum.ReportsBrokersExport,
                title: 'Brokers - Export',
            },
            {
                key: PermissionsEnum.ReportsInvestmentsExport,
                title: 'Investments - Export',
            },
            {
                key: PermissionsEnum.ReportsInvestorsFinRegister,
                title: 'Investors - FIN Register',
            },
            {
                key: PermissionsEnum.ReportsInvestorsFitCashBalances,
                title: 'Investors - FIT Cash Balances',
            },
            {
                key: PermissionsEnum.ReportsInvestorsFitCashTransactions,
                title: 'Investors - FIT Cash Transactions',
            },
            {
                key: PermissionsEnum.ReportsInvestorsFitInvestmentBalances,
                title: 'Investors - FIT Investment Balances',
            },
            {
                key: PermissionsEnum.ReportsInvestorsTfn,
                title: 'Investors - TFN',
            },
            {
                key: PermissionsEnum.ReportsLeadsExport,
                title: 'Leads - Export',
            },
            {
                key: PermissionsEnum.ReportsLeadsTimeInStatus,
                title: 'Leads - Time In Status',
            },
            {
                key: PermissionsEnum.ReportsLoansExport,
                title: 'Loans - Export',
            },
            {
                key: PermissionsEnum.ReportsLoansBorrowers,
                title: 'Loans - Borrowers',
            },
            {
                key: PermissionsEnum.ReportsLoansPayments,
                title: 'Loans - Payments',
            },
            {
                key: PermissionsEnum.ReportsLoansProperties,
                title: 'Loans - Properties',
            },
            {
                key: PermissionsEnum.ReportsLoansPoolCut,
                title: 'Loans - Pool Cut Report',
            },
            {
                key: PermissionsEnum.ReportsWarehouseLoanBalances,
                title: 'Warehouses - Loan Balances',
            },
            {
                key: PermissionsEnum.ReportsWarehouseFwt2PoolCut,
                title: 'Warehouses - FWT2 Pool Cut Report',
            },
        ],
        key: PermissionsEnum.Reports,
        title: 'Reports',
    },
    {
        children: [
            {
                key: PermissionsEnum.WarehouseLoanSell,
                title: 'Sell Warehouse Loan',
            },
            {
                key: PermissionsEnum.WarehouseActiveReport,
                title: 'Active Report',
            },
            {
                key: PermissionsEnum.WarehousesPortfolioDashboard,
                title: 'Portfolio Dashboard',
            },
        ],
        key: PermissionsEnum.Warehouses,
        title: 'Warehouses',
    },
];

export default permissionsTree;
